import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";
import type { THomeAnimation } from "typings/home";

const container = createContainer(() => {
  const [curAnimation, _setAnimation] = useState<THomeAnimation | null>(null);
  const [initDisplayCover, _setDisplayCover] = useState<THomeAnimation | null>(
    null
  );
  const setAnimation = useCallback(
    (v: React.SetStateAction<THomeAnimation | null>) => _setAnimation(v),
    []
  );
  const setDisplayCover = useCallback(
    (v: React.SetStateAction<THomeAnimation | null>) => _setDisplayCover(v),
    []
  );
  return {
    curAnimation,
    setAnimation,
    setDisplayCover,
    initDisplayCover,
  } as const;
});

export const HomeAnimationProvider = container.Provider;
export const useHomeAnimation = container.useContainer;
export default container;
