import React from "react";
import styled from "styled-components";

export const flex = {
  row: {
    display: "flex",
    flexDirection: "row",
  } as const satisfies React.CSSProperties,
  column: {
    display: "flex",
    flexDirection: "column",
  } as const satisfies React.CSSProperties,
  center: {
    alignItems: "center",
    justifyContent: "center",
  } as const satisfies React.CSSProperties,
  between: {
    alignItems: "center",
    justifyContent: "space-between",
  } as const satisfies React.CSSProperties,
} as const;

export const flexStyles = {
  row: `
    display: flex;
    flex-direction: row;
  `,
  column: `
    display: flex;
    flex-direction: column;
  `,
  center: `
    align-items: center;
    justify-content: center;
  `,
  between: `
    align-items: center;
    justify-content: space-between;
  `,
  absoluteFill: `
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  `,
} as const;
export const absoluteFill = {
  position: "absolute",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
} as const satisfies React.CSSProperties;

export const absoluteFillStyles = `
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

export const minDesktopFullSize = {
  width: "100vw",
  height: "100vh",
  minWidth: 1440,
  minHeight: 720,
} as const satisfies React.CSSProperties;
export const minDesktopSize = {
  minWidth: 1440,
  minHeight: 720,
} as const satisfies React.CSSProperties;

export const minDesktopFullSizeStyles = `
  width: 100vw;
  height: 100vh;
  min-width: 1440px;
  min-height: 720px;
`;
export const minDesktopSizeStyles = `
  min-width: 1440px;
  min-height: 720px;
`;

const screenBreakpoints = {
  s: "812px",
  m: "1024px",
  l: "1366px",
  xl: "1920px",
  s_n: 812,
  m_n: 1024,
  l_n: 1366,
  xl_n: 1920,
} as const;

export const commons = {
  flex,
  flexStyles,
  absoluteFill,
  absoluteFillStyles,
  minDesktopFullSize,
  minDesktopFullSizeStyles,
  minDesktopSize,
  minDesktopSizeStyles,
  screenBreakpoints,
} as const;

export const FlexBox = styled.div<{ row?: boolean }>`
  position: relative;
  ${(p) => !p.row && p.theme.flexStyles.column}
  ${(p) => !!p.row && p.theme.flexStyles.row}
  ${(p) => p.theme.flexStyles.center}
`;

export const FlexBlank = styled.div`
  flex: 1;
`;

export const FlexRow = styled.div<{ center?: boolean }>`
  position: relative;
  ${(p) => p.theme.flexStyles.row}
  ${(p) => p.center && p.theme.flexStyles.center}
`;

export const FlexColumn = styled.div<{ center?: boolean }>`
  position: relative;
  ${(p) => p.theme.flexStyles.column}
  ${(p) => p.center && p.theme.flexStyles.center}
`;
