import React, { Suspense } from "react";
import { IntlProvider } from "langs/intl";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { HelmetProvider } from "react-helmet-async";
import { HomeAnimationProvider } from "contexts/homeAnimationContext";
import { theme } from "styles/theme";
import { AppRoutes } from "constants/routes";
import FallbackElement from "components/FallbackElement";
import "normalize.css";
import "./fonts/be-vietnam-pro-v10.css";
import "./fonts/dm-mono-latin-v10.css";
import "./fonts/neue-montreal.css";

// const Home = React.lazy(() => import("pages/Home"));
const Home = React.lazy(() => import("pages/Landing"));
const Company = React.lazy(() => import("pages/Company"));
const Product = React.lazy(() => import("pages/Product"));
const Service = React.lazy(() => import("pages/Service"));

const basename = process.env.REACT_APP_BASE_URL;

const router = createBrowserRouter(
  [
    {
      path: AppRoutes.home,
      element: <Home />,
      errorElement: <FallbackElement hideText />,
    },
    {
      path: AppRoutes.company,
      element: <Company />,
    },
    {
      path: AppRoutes.product,
      element: <Product />,
    },
    {
      path: AppRoutes.service,
      element: <Service />,
    },
  ],
  { basename }
);

function App() {
  return (
    <IntlProvider>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <HomeAnimationProvider>
            <Suspense fallback={<FallbackElement hideText />}>
              <RouterProvider
                router={router}
                fallbackElement={<FallbackElement />}
              />
            </Suspense>
          </HomeAnimationProvider>
        </HelmetProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
