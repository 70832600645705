import {
  IntlShape,
  RawIntlProvider,
  IntlConfig,
  createIntlCache,
  createIntl,
} from "react-intl";
import { useMemo } from "react";

// import { LangType } from "constants/language";
import { getStoreLanguage } from "./language";
import enUSJson from "./data/en-US.json";
import zhHansJson from "./data/zh-Hans.json";

const createPerIntl = (locale: string, messages: IntlConfig["messages"]) => {
  // This is optional but highly recommended
  // since it prevents memory leak
  const cache = createIntlCache();

  return createIntl(
    {
      locale,
      messages,
      onError: () => {
        /** disable error */ return null;
      },
    },
    cache
  );
};

const language2Intl: { [key: string]: any } = {
  "en-US": createPerIntl("en-US", enUSJson),
  "zh-Hans": createPerIntl("zh-Hans", zhHansJson),
  // "zh-Hant-HK": createPerIntl("ko-KR", zhHKJson),
};

export const getIntl = (lang: string) => language2Intl[lang];

export function IntlProvider({ children }: React.PropsWithChildren<{}>) {
  const lang = getStoreLanguage();
  const intl = useMemo(() => {
    return getIntl(lang);
  }, [lang]);

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
}

const intl = {
  formatMessage: (...args: Parameters<IntlShape["formatMessage"]>) => {
    return getIntl(getStoreLanguage()).formatMessage(...args);
  },
};

// const useLanguage = (): [LangType, (lang: LangType) => void] => {
//   const lang = useBehavior(language.$current);
//   const setLang = (lang: LangType) => {
//     language.setCurrent(lang);
//   };
//   return [lang, setLang];
// };

export default intl;
// export { useLanguage };
