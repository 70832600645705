type TFontsStyles = Pick<
  React.CSSProperties,
  "fontFamily" | "fontSize" | "fontWeight" | "lineHeight" | "letterSpacing"
>;

const navFontFamily = `'Be Vietnam Pro'`;
const titleFontFamily = `'DM Mono'`;
const fontFamily = `'pp_neue_montreal'`;
const navFonts = {
  fontFamily: navFontFamily,
  fontWeight: "bold",
  lineHeight: 1,
} as const satisfies TFontsStyles;
/** @description only used in product modal */
const sloganIndex = {
  fontFamily: titleFontFamily,
  fontSize: 28,
  lineHeight: 1.5,
  letterSpacing: 0,
} as const satisfies TFontsStyles;
/** @description only used in product modal */
const slogan = {
  fontFamily,
  fontWeight: "normal",
  fontSize: 28,
  lineHeight: 1.3,
  letterSpacing: 0.06,
} as const satisfies TFontsStyles;
const title = {
  fontFamily: titleFontFamily,
  fontSize: 24,
  fontWeight: 300,
  lineHeight: 1.5,
  letterSpacing: 0,
} as const satisfies TFontsStyles;
const paragraph = {
  fontFamily,
  fontWeight: "normal",
  fontSize: 30,
  lineHeight: 1.3,
  letterSpacing: 0.06,
} as const satisfies TFontsStyles;

export const fontStyles = {
  title,
  slogan,
  sloganIndex,
  paragraph,
  navFonts,
} as const;

const createFont = <T extends TFontsStyles>(font: T) =>
  `
  font-family: ${font.fontFamily};
  font-size: ${font.fontSize}px;
  font-weight: ${font.fontWeight};
  line-height: ${font.lineHeight};
  ${
    typeof font.letterSpacing !== "undefined"
      ? `letter-spacing: ${font.letterSpacing}em;`
      : ""
  }
` as unknown as typeof font;

export const fonts = {
  title: createFont(title),
  slogan: createFont(slogan),
  sloganIndex: createFont(sloganIndex),
  paragraph: createFont(paragraph),
  navFonts: createFont(navFonts),
} as const;
