export enum LangType {
  EN = "en-US",
  ZH = "zh-Hans",
  KO = "ko-KR",
  HK = "zh-Hant-HK",
}

// moment i18n
export const momentLangMap = {
  [LangType.EN]: "en",
  [LangType.ZH]: "zh-cn",
  // [LangType.KO]: "ko",
  [LangType.HK]: "zh-hk",
};

// 语言映射
export const languagesMap: [RegExp, LangType][] = [
  // hk
  [/^zh-tw/i, LangType.HK],
  [/^zh-hk/i, LangType.HK],
  [/^zh-hant/i, LangType.HK],
  // cn
  [/^zh-hans/i, LangType.ZH],
  [/^zh/i, LangType.ZH],
  // en
  [/^en/i, LangType.EN],
  // ko
  [/^ko/i, LangType.KO],
];

export const isSpaceCN = () => {
  // return /domain/.test(document.domain);
  return false;
};

// 默认语言
export const defaultLanguage: LangType = isSpaceCN()
  ? LangType.ZH
  : LangType.EN;

// 支持的语言
export const supportLanguages = [LangType.EN, LangType.ZH];

const normalizeLanguage = (lang: string) => {
  // 精确匹配：en-US => en-US
  const arr = supportLanguages.map((item) => item.toLocaleLowerCase());
  const i = arr.indexOf(lang.toLocaleLowerCase());
  if (i !== -1) return supportLanguages[i];

  // 映射
  for (let item of languagesMap) {
    if (item[0].test(lang) && supportLanguages.includes(item[1]))
      return item[1];
  }

  // 默认值
  return "";
};

// 内存语言
let _language: LangType = getLanguage()?.lang || defaultLanguage;
export const getStoreLanguage = () => _language;
export const setStoreLanguage = (v: LangType) => {
  if (supportLanguages.indexOf(v) === -1) {
    console.error(`error lang ${v}`);
    return;
  }
  _language = v;
  localStorage.setItem("language", v);
};

// 选择语言
export const LangSelects: { value: LangType; text: string }[] = [
  {
    value: LangType.EN,
    text: "English",
  },
  {
    value: LangType.ZH,
    text: "简体中文",
  },
  {
    value: LangType.HK,
    text: "繁體中文",
  },
  // {
  //   value: LangType.KO,
  //   text: "한국어",
  // },
];

// 获取path中的语言, 必须精确匹配才能当做语言，否则当成普通路径
export function getPathLanguage(location: Partial<Location> = window.location) {
  const path = location.pathname || "";
  const first = path.split("/")[1];
  return first || "";
}

// 获取参数中的语言, 必须精确匹配才能当做语言，否则当成普通路径
export function getQueryLanguage(
  location: Partial<Location> = window.location
) {
  const search = location.search || "";
  const i = search.indexOf("?");
  let qs,
    qsArr,
    query: Record<string, string> = {};

  // no query
  if (i === -1) return "";

  // query lang
  // https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/String/substr
  // qs = search.substr(i + 1);
  qs = search.substring(i + 1);
  qsArr = qs.split("&");
  qsArr.forEach(function (str) {
    let arr = str.split("=");
    query[arr[0]] = decodeURIComponent(arr[1]);
  });

  return query["lang"] || "";
}

// 获取浏览器语言
export function getBrowserLanguage() {
  return navigator.language || (window.navigator as any).browserLanguage || "";
}

// 获取上一次切换的语言
export function getStoredLanguage() {
  const lang = localStorage.getItem("language");
  return lang;
}

// get cur lang
// return {lang, type}
export function getLanguage(args?: {
  types?: string[];
  defaultLanguage?: LangType;
  location: Partial<Location>;
}) {
  let {
    // 目前只取用户上次保存的语言，否则为默认语言
    types = [
      // "path",
      // "query",
      "store",
      // "browser",
    ],
    defaultLanguage: def = defaultLanguage,
    location = window.location,
  } = args || {};

  let langs: Record<string, string>,
    lang: string,
    useType = null;

  langs = {
    path: getPathLanguage(location) || "",
    query: getQueryLanguage(location) || "",
    browser: getBrowserLanguage() || "",
    store: getStoredLanguage() || "",
  };
  lang = "";
  types.forEach((type) => {
    if (lang) return;
    if (langs[type]) {
      const res = normalizeLanguage(langs[type]);
      if (res) {
        useType = type;
        lang = res;
      }
    }
  });

  return { lang: (lang as LangType) || def, type: useType };
}

// class Language {
//   // 当前设置过的语言
//   initialize = false;
//   current: string = defaultLanguage;

//   constructor() {
//     this.initIfNeed();
//   }

//   setCurrent(lang: LangType) {
//     if (supportLanguages.indexOf(lang) === -1) {
//       console.error(`error lang ${lang}`);
//       return;
//     }
//     this.current = lang;
//     localStorage.setItem("language", lang);
//   }

//   initIfNeed() {
//     if (this.initialize) return;
//     const { lang } = this.getLanguage();
//     this.setCurrent(lang);
//     this.initialize = true;
//   }
// }

// const language = new Language();

// export default language;
